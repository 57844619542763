import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, switchMap } from 'rxjs/operators';
import { AppState } from '@pepconnect/state';
import { setTiers } from '@pepconnect/state/auth/auth.actions';
import { Tier } from '@pepconnect/models/content/tier.model';
import { User } from '@pepconnect/models/user/user.model';
import { ApiService } from './api.service';
import { Discipline } from '@pepconnect/models/content/discipline.model';
import { TranslateService } from '@ngx-translate/core';
import { BaseStateService } from '@pepconnect/shared/base/base-state-service/base-state-service';
import { of } from 'rxjs';

declare const cloudMedia: any;

@Injectable({
  providedIn: 'root'
})
export class TierService extends BaseStateService {

  constructor(private api: ApiService, protected store: Store<AppState>, private translateSvc: TranslateService) { super(store); }

  fetchTiers(locale: number) {
    // need to add a common handler that injects the angularjs logger service and then pipe the error to that
    return this.api.get<Tier[]>('v2/startup/tiers?locale=' + locale);
  }

  hydrateTiers(user: User) {
    this.fetchTiers(user.locale.id).pipe(first()).subscribe((data: Tier[]) => {
      this.store.dispatch(setTiers({ tiers: data }))
    })
  }

  fetchTier(contentLocale: string, friendlyUrl: string, userLocale: number) {
    // need to add a common handler that injects the angularjs logger service and then pipe the error to that
    return this.api.get<Tier>(`v2/tiers/${contentLocale}/${friendlyUrl}?locale=${userLocale}`);
  }

  /** Adds an event discipline to a tier if the tier requires events to be shown () */
  transformTier(tier: Tier) {
    return this.$userLocale.pipe(first(), switchMap(userLocale => {
      const transformed = this.addEventDiscipline(tier, userLocale.locale);
      return of(transformed)
    }));
  }

  /** Adds the events discipline if showEvents is true on the tier */
  addEventDiscipline(tier: Tier, locale: string) {
    if (tier.showEvents && !tier.disciplines.some(({ id }) => id === 3100)) {

      const eventMenuItem = this.getEventDiscipline(locale);
      // update disciplines array with new menu item
      const updatedDisciplines = [...tier.disciplines, eventMenuItem];

      tier = Object.assign({}, tier);
      // assgin updated discpline list to tier object
      tier.disciplines = updatedDisciplines;
    }
    return tier;
  }

  private getEventDiscipline(locale: string) {
    const eventMenuItem = {
      id: 3100,
      name: this.translateSvc.instant('EVENTS_TEXT'),
      color: "#9933cc",
      trainingAreas: [{
        name: this.translateSvc.instant('VIEW_ALL_EVENTS_TEXT'),
        links: [{
          rel: "page", href: "/" + locale + "/events"
        }],
        imageURL: cloudMedia.Utils.cloudTransformUrl('CMS/Images/B01E68AA-74F3-4AF6-8534-CABB880C1F65.jpg')
      }],
      hasEmployeeOnlyContent: false,
      imageURL: '',
      employeeOnly: false
    } as Discipline;
    return eventMenuItem;
  }
}
