import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateFn, CanActivateChildFn } from '@angular/router';
import { of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AppState } from '@pepconnect/state';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { LocaleService } from '@pepconnect/services/locale.service';
import { inject } from '@angular/core';

/**
 *  This is essentially useless at the moment because AngularJS does not respect the guards
 *  but in the future this is the preferred method to making sure that the users locale matches
 *  the locale in the route. Once we're off AngularJS this would replace the functionality within
 *  RouteListenerService.listenforRouteChange that duplicates this function, but until then that
 *  functionality needs to stay there because it fires regardless of NGX or AngluarJS
 * **/

export const canActivateRouteLocale: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store<AppState>);
  const router = inject(Router);
  const localeService = inject(LocaleService);
  return store.select(selectAuthState).pipe(first(authState => authState.isInitialized), switchMap(authState => {
    // check if this route _should_ have a locale (some routes do not like sso)
    if (route.paramMap.has('locale')) {
      // make sure the locale is not blank
      const locale = route.paramMap.get('locale');
      if (!locale || authState.user.locale.locale !== locale) {
        // if the users locale does not match the route locale, redirect the user
        localeService.updateRouteLocaleParam(authState.user.locale, router, route);
        return of(false);
      }
    }
    return of(true);
  }));
}

export const canActivateRouteLocaleChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => canActivateRouteLocale(route, state);
