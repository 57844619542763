import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@pepconnect/services/api.service';
import { EnvironmentService } from '@pepconnect/services/environment.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FileBlob } from './api-main.service';

@Injectable({
  providedIn: 'root'
})
export class ApiMockService extends ApiService {

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    super();
  }

  // list of locales available in inline
  private inlineLocales = new Set(['en-us', 'es-es', 'de-de', 'fr-fr', 'it-it', 'pl-pl', 'pt-br', 'id-id', 'ja-jp', 'zh-cn', 'ko-kr', 'ru-ru', 'tr-tr', 'vi-vn', 'th-th', 'fi-fi', 'da-dk', 'nl-nl', 'nb-no', 'es-mx', 'pt-pt']);

  /**
  * Implementation of ApiService.get.  This should not be called directly
  * @param endpoint
  */
  get<T>(endpoint: string): Observable<T> {
    // trim off any query strings since we don't need these for mock data
    endpoint = endpoint.split('?')[0];

    // for pages(content) that has locale in the path, ignore them and take to same mocked file
    const segments = endpoint.split('/').filter(s => !this.inlineLocales.has(s));
    endpoint = segments.join('/');

    return this.http.get<T>(`${this.env.mockUrl}${endpoint}.json`).pipe(
      catchError(err => {
        return throwError(() => err)
      })
    );

  }

    /**
  * Implementation of ApiService.get.  This should not be called directly
  * @param endpoint
  */
   getBlob(endpoint: string): Observable<FileBlob> {
    // trim off any query strings since we don't need these for mock data
    endpoint = endpoint.split("?")[0];

    return this.http.get(`${this.env.mockUrl}${endpoint}.json`).pipe(
      map(r => new FileBlob()),
      catchError(err => {
        return throwError(() => err)
      })
    );

  }

  /**
   * Implementation of ApiService.post.  This should not be called directly
   * @param endpoint
   * @param data
   */
  post<T>(endpoint: string, data: any): Observable<T> {
    return this.get(endpoint);
  }

  /**
 * Implementation of ApiService.postJSON.  This should not be called directly
 * @param endpoint
 * @param data
 */
  postJson<T>(endpoint: string, data: any): Observable<T> {
    return of({} as T)
  }

  /**
* Implementation of ApiService.postFile.  This should not be called directly
* @param endpoint
* @param data
*/
  postFile<T>(endpoint: string, data: any): Observable<T> {
    return of({} as T)
  }

  /**
  * Implementation of ApiService.postFileWithToken
  * @param endpoint
  * @param data
  */
  postFileWithToken<T>(endpoint: string, data: any, token: string): Observable<T> {
    return of({} as T)
  }

  /**
* Implementation of ApiService.postFile.  This should not be called directly
* @param endpoint
* @param data
*/
  postFormData<T>(endpoint: string, data: any): Observable<T> {
    return of({} as T)
  }


  /**
   * Implementation of ApiService.put.  This should not be called directly.
   * @param endpoint
   * @param data
   */
  put<T>(endpoint: string, data: any): Observable<T> {
    return of({} as T)
  }

  delete<T>(endpoint: string): Observable<T> {
    return of({} as T)
  }

  sendBeacon(endpoint: string, data: any) {
    return of()
  }

}
