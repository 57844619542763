import { Injectable } from '@angular/core';
import { InitializedVideoAction, ProgressedVideoAction, PlayedVideoAction, SeekedVideoAction, CompletedVideoAction } from '@pepconnect/models/analytics/video-track.model';
import { Observable, take } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class VideoDataTrackService {

  constructor(private api: ApiService) { }

  getSessionGuid(): Observable<string> {
    return this.api.get('v2/analytics/session/new');
  }

  trackInitializedVideo(initializedVideoAction: InitializedVideoAction) {
    return this.api.sendBeacon('v2/analytics/video/initialized', initializedVideoAction).pipe(take(1)).subscribe();
  }

  trackPlayedVideo(playedVideoAction: PlayedVideoAction) {
    return this.api.sendBeacon('v2/analytics/video/played', playedVideoAction).pipe(take(1)).subscribe();
  }

  trackPausedVideo(pausedVideoAction: ProgressedVideoAction) {
    return this.api.sendBeacon('v2/analytics/video/paused', pausedVideoAction).pipe(take(1)).subscribe();
  }

  trackSeekedVideo(seekedVideoAction: SeekedVideoAction) {
    return this.api.sendBeacon('v2/analytics/video/seeked', seekedVideoAction).pipe(take(1)).subscribe();
  }

  trackCompletedVideo(completedVideoAction: CompletedVideoAction) {
    return this.api.sendBeacon('v2/analytics/video/completed', completedVideoAction).pipe(take(1)).subscribe();
  }

  trackTerminatedVideo(terminatedVideoAction: ProgressedVideoAction) {
    return this.api.sendBeacon('v2/analytics/video/terminated', terminatedVideoAction).pipe(take(1)).subscribe();
  }

}


